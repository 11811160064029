<template>
  <div  >

    <h5 class=" font-weight-regular mb-1"
        :style="`color : ${inputErrors.paymentPlan ? wsWARNING : wsACCENT}`"
    >
      {{  $t('ChoosePaymentPlan')  }}
    </h5>
    <div  :style="`border: 1px solid ${inputErrors.paymentPlan ? wsWARNING : wsBACKGROUND}; background-color: #ffffff`">
      <div @click="entityData.payment_plan_id = plan.value; inputErrors.paymentPlan = null"
           v-for="(plan,index) in entity.payment_plans" :key="index + 'wave'"
           class="pa-2 d-flex align-items-center pointer"
           :style="index > 0 ? 'border-top: 1px solid var(--wsBACKGROUND)' : null"
      >
        <v-icon class="mr-2" :color="wsACCENT">{{ entityData.payment_plan_id === plan.value ?  'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
        <div>
          <h5 class="wsACCENT">{{ plan.text }}</h5>
          <h4 class="wsDARKER" >{{ plan.price }} {{ BUSINESS_CURRENCY }}</h4>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
export default {
  name: "courseCartPaymentPlans",
  components : {

  },
  props : {
    value : {
      type : Object,
      default() {
        return {}
      }
    },
    entity : {
      type : Object,
      default() {
        return {}
      }
    },
    inputErrors : {
      type : Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      entityData : {}
    }
  },
  watch : {
    value : {
      handler() {
        if (!this.EQ(this.value , this.entityData)) {
          this.entityData = this.COPY(this.value)
        }
      },
      deep : true
    },
    entityData : {
      handler() {
        if (!this.EQ(this.value , this.entityData)) {
         this.$emit('input' , this.COPY(this.entityData))
        }
      },
      deep : true
    }
  },
  mounted() {
    if (this.value) {
      this.entityData = this.COPY(this.value)
    }
  }
}
</script>



<style scoped>

</style>