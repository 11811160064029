<template>
  <div>
    <h5 v-if="label"
        class="font-weight-regular mb-1"
        :style="`color : ${error ? wsWARNING : wsACCENT}`"
    >
      {{ $t(label) }}
    </h5>
    <div class="d-flex align-center pa-2 px-2" :style="inputStyle">
      <input
          v-model="text"
          type="text"
          style="outline: none; width: 100%; background: white"
          :placeholder="$t(placeholder)"
      >
      <v-icon
          v-if="clearable && !!text"
          @click="$emit('clear' , text = null)"
          :color="clearIconColor"
          :class="[{'mr-2' : !!actionText}]"
      >
        mdi-close-circle
      </v-icon>
      <h5 class="linkHover" @click="$emit('action')" v-if="actionText">
        {{ $t(actionText) }}
      </h5>

    </div>
  </div>

</template>

<script>
export default {
  name: "cartInputField",
  props : {
    value : {
      type : String,
      default : ''
    },
    actionText : {
      type : String,
    },
    label : {
      type : String,
    },
    placeholder : {
      type : String,
    },
    clearable : {
      type: Boolean,
    },
    error : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text : ''
    }
  },
  computed : {
    clearIconColor() {
      return this.wsACCENT
    },
    inputStyle() {
      let style = ''
      style += `background-color : ${this.wsWHITE};`
      style += `border : 1px solid ${this.error ? this.wsWARNING : this.wsBACKGROUND};`
      return style
    }
  },
  watch : {
    text() {
      if (this.value !== this.text) {
        this.$emit('input' , this.text)
      }
    },
    value() {
      if (this.value !== this.text) {
        this.text = this.value
      }
    }
  },
  mounted() {
    if (this.value) {
      console.log('this.value' , this.value)
      this.text = this.value
    }
  }
}
</script>



<style scoped>

</style>