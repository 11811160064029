<template>
  <cart
      title="PurchaseCourse"
      :init-function="getCourse"
      @embed-data="embedData = $event"
      :embed="embed"
      :order-complete="orderComplete"
  >

    <template #order.complete>
      <div class="d-flex flex-column fill-height pa-4">

        <div
             class="pa-5"
             style="border : 1px solid var(--wsBACKGROUND); background: #ffffff"
        >
          <h4 class="wsDARKER ">Замовлення: {{ order.order_number }} </h4>

          <h5 class="wsACCENT mt-5 font-weight-regular">Після оплати перейдіть в особистий кабінет для доступу до курсу</h5>


          <div v-if="order.user_password" class="mt-5">
            <h5 class="wsDARKER">
              <span class="font-weight-regular">{{ $t('Email') }}</span>
              {{ order.email }}
            </h5>
            <h5 class="wsDARKER">
              <span class="font-weight-regular mt-3">{{ $t('Password') }}</span>
              {{ order.user_password }}
            </h5>
          </div>

          <ws-button new-window class="mt-5" :href="order.dashboard_url" block label="ClientPanel" />


        </div>







      </div>
    </template>

    <template #default>
      <course-cart-course-details
          :entity="entity"
      />
      <course-cart-user-data
          v-model="enrollmentData"
          :entity="entity"
          :input-errors="inputErrors"
          class="mb-5"
      />

      <course-cart-promocode
          :entity="entity"
          @set-discount="discount = $event"
      />

      <course-cart-payment-plans
          v-if="entity.has_payment_plans"
          v-model="enrollmentData"
          :input-errors="inputErrors"
          :entity="entity"
          class="mt-5"
      />

      <course-cart-enrollment-waves
          v-if="entity.online"
          v-model="enrollmentData"
          :entity="entity"
          :input-errors="inputErrors"
          class="my-5"
      />

    </template>


    <template #footer>
      <course-cart-price
          :entity="entity"
          :promo-discount="discount"
          :enrollment-data="enrollmentData"
          class="mb-5"
      />
      <ws-button
          @click="purchaseCourse"
          label="Purchase"
          left-icon="mdi-basket-outline"
          height="50"
          block

      />
    </template>
  </cart>

</template>


<script>
import Cart from "@/components/pages/technical/cart/Cart.vue";
import {mapActions} from "vuex";
import courseCartCourseDetails from "@/components/pages/technical/cart/course/UI/courseCartCourseDetails.vue";
import courseCartPromocode from "@/components/pages/technical/cart/course/UI/courseCartPromocode.vue";
import courseCartUserData from "@/components/pages/technical/cart/course/UI/courseCartUserData.vue";
import courseCartPaymentPlans from "@/components/pages/technical/cart/course/UI/courseCartPaymentPlans.vue";
import courseCartEnrollmentWaves from "@/components/pages/technical/cart/course/UI/courseCartEnrollmentWaves.vue";
import courseCartPrice from "@/components/pages/technical/cart/course/UI/courseCartPrice.vue";

export default {
  name: "CourseEnrollButton",
  components : {
    Cart,
    courseCartCourseDetails,
    courseCartPromocode,
    courseCartUserData,
    courseCartPaymentPlans,
    courseCartEnrollmentWaves,
    courseCartPrice
  },
  props : {
    courseUuid : {
      type : String
    },
    embed : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      orderComplete : false,
      entity : {},
      uuid : null,
      discount : 0,

      enrollmentData : {
        payment_plan_id : null,
        enrollment_wave_id : null,
        quantity : 1
      },

      // Technical
      embedData : {},
      paymentUrl : null,
      order : {},
      inputErrors : {},
      merchantId : null
    }
  },
  methods : {
    ...mapActions('courses', [
      'GET_COURSE_BUTTON_PUBLIC'
    ]),

    ...mapActions('cart', [
        'COURSE_FAST_PURCHASE'
    ]),


    handleCourseUuid() {
      if (this.embed) {
        this.uuid = this.embedData.course_id
        if (this.embedData.merchant_id) {
          this.merchantId = this.embedData.merchant_id
        }
      } else {
        this.uuid = this.courseUuid
      }

      return !!this.uuid
    },

    async getCourse() {
      this.enrollmentData = {
        payment_plan_id : null,
        enrollment_wave_id : null,
        quantity : 1
      }
      if (!this.handleCourseUuid()) {
        return { result : false , error : 'Course Id is missing'}
      }

      let result = await this.GET_COURSE_BUTTON_PUBLIC(this.uuid)
      if (!result) {
        return { result : false , error : 'Course not Found'}
      }

      this.entity = result
      this.handleEnrollData()

      return { result : true }

    },

    handleEnrollData() {
      if (this.entity.online && this.entity.enrollment_waves && this.entity.enrollment_waves.length === 1) {
        this.enrollmentData.enrollment_wave_id = this.entity.enrollment_waves[0].uuid
        this.enrollmentData = this.COPY(this.enrollmentData)
      }
    },

    validateEnrollmentData () {

      if (!this.enrollmentData.email) {
        this.inputErrors = { email : true}
        this.notify(this.$t('EnterEmail') , 'warning')
        return false
      }

      if (!this.VALIDATE_EMAIL(this.enrollmentData.email) ) {
        this.inputErrors = { email : true}
        this.notify(this.$t('WrongEmail') , 'warning')
        return false
      }

      if (this.entity.has_payment_plans && !this.enrollmentData.payment_plan_id) {
        this.inputErrors = { paymentPlan : true}
        this.notify(this.$t('BusinessPlanNotSelected') , 'warning')
        return false
      }

      if (this.entity.online && !this.enrollmentData.enrollment_wave_id) {
        this.inputErrors = { enrollmentWave : true}
        this.notify(this.$t('ChooseEnrollmentSchedule') , 'warning')
        return false
      }

      return true
    },

    async purchaseCourse() {

      if (!this.validateEnrollmentData() ) {
        return
      }

      this.enrollmentData.course_id = this.uuid
      if (this.merchantId) {
        this.enrollmentData.merchant_id = this.merchantId
      }


      let result = await this.COURSE_FAST_PURCHASE(this.enrollmentData)
      if (!result) {
        this.ERROR(this.$store.state.ajax.error)
        return
      }



      if ( this.embed ) {
        window.parent.postMessage({
          action : 'ws_redirect_to_payment',
          link : result.payment_url
        }, '*');
      } else {
        const paymentLink = document.createElement('a');
        paymentLink.href = result.payment_url
        paymentLink.target = '_blank'
        window.document.body.appendChild(paymentLink)
        paymentLink.click()
        paymentLink.remove()
      }

      this.orderComplete = true
      this.order = result

    }


  }
}
</script>


<style scoped>

</style>