<template>
  <div>
    <!-- Course Details -->
    <div class="align-center mb-5"
         :class="[{'d-flex' : !IS_MOBILE}]"
    >
      <v-img :class="[{'mr-3' : !IS_MOBILE}]"
             width="100px" height="100px" style="object-fit: fill" v-if="entity.img" :src="entity.img" alt="" />
      <div>
        <h3 class="wsDARKER"> {{  entity.name  }}</h3>
        <h5 class="font-weight-regular wsDARKER"> {{  entity.short_description  }}</h5>
      </div>
    </div>

    <v-divider class="mb-5" style="border-color : var(--wsBACKGROUND)"  />
  </div>
</template>

<script>
export default {
  name: "courseCartPreview",
  props : {
    entity : {
      type : Object,
      default() {
        return {}
      }
    }
  }
}
</script>



<style scoped>

</style>