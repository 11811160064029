<template>
<div>
  <cart-input-field
      v-model="entityData.email"
      label="Email"
      placeholder="EnterEmail"
      :error="inputErrors.email"
      @input="inputErrors.email = null"
  />

  <h5 class="wsDARKLIGHT font-weight-regular mt-2">На вказану пошту будуть надіслані існтрукції щодо доступу до курсу, після оплати</h5>

</div>
</template>

<script>
import cartInputField from "@/components/pages/technical/cart/course/UI/cartInputField.vue";
export default {
  name: "courseCartUserData",
  components : {
    cartInputField
  },
  props : {
    value : {
      type : Object,
      default() {
        return {}
      }
    },
    entity : {
      type : Object,
      default() {
        return {}
      }
    },
    inputErrors : {
      type : Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      entityData : {}
    }
  },
  watch : {
    value : {
      handler() {
        if (!this.EQ(this.value , this.entityData)) {
          this.entityData = this.COPY(this.value)
        }
      },
      deep : true
    },
    entityData : {
      handler() {
        if (!this.EQ(this.value , this.entityData)) {
         this.$emit('input' , this.COPY(this.entityData))
        }
      },
      deep : true
    }
  },
  mounted() {
    if (this.value) {
      this.entityData = this.COPY(this.value)
    }
  }
}
</script>



<style scoped>

</style>