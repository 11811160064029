<template>
  <div>

    <cart-input-field
        v-if="!promocode"
        v-model="promocodeInput"
        label="PromoCode"
        placeholder="EnterPromocode"
        action-text="Activate"
        @action="getPromoCode"
        @clear="clear"
        clearable
    />

    <div class="d-flex justify-space-between" v-else>
      <h4 class="wsDARKER"> <span class="font-weight-regular">{{$t('PromoCode')}}:</span>  {{ promocode  }} - {{promocodeDiscount}}% </h4>
      <v-icon @click="clear" :color="clearIconColor">mdi-close-circle</v-icon>
    </div>


  </div>
</template>

<script>
import cartInputField from "@/components/pages/technical/cart/course/UI/cartInputField.vue";
import {mapActions} from "vuex";
export default {
  name: "courseCartPreview",
  components: {
    cartInputField
  },
  props : {
    entity : {
      type : Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      promocodeInput : '',
      promocode : '',
      promocodeDiscount : null
    }
  },
  computed : {
    clearIconColor() {
      return this.wsACCENT
    }
  },
  methods : {
    ...mapActions('cart', [
        'GET_PROMOCODE'
    ]),

    clear() {
      this.promocodeInput = null;
      this.promocodeDiscount = null ;
      this.promocode = null;
      this.$emit('set-discount' ,0 )
    },
    async getPromoCode() {
      let data = {
        code : this.promocodeInput.trim(),
        course_id : this.entity.uuid
      }
      let result = await this.GET_PROMOCODE(data)
      if ( !result ) {
        return this.notify(this.$t('PromoCodeNotFount') , 'warning')
      }
      this.promocodeDiscount = result.percent
      this.promocode = result.code

      this.$emit('set-discount' ,this.promocodeDiscount )
      this.notify(this.$t('PromoCodeFound') , 'success')

    },
  }
}
</script>



<style scoped>

</style>