<template>
<div>

  <!-- No Plan Warning-->
  <div v-if="noPlanSelectedWarning">
    <h4 class="font-weight-regular text-center wsDARKER">{{ $t('SelectPaymentPlan') }}</h4>
  </div>

  <!-- Order Price -->
  <div v-else>

    <!-- Discount -->
    <h5 class="wsDARKER text-center"  style="text-decoration: line-through">
      {{ $t('OldPrice') }}: {{ totalPrice }} {{ BUSINESS_CURRENCY }}
    </h5>

    <!-- Old Price -->
    <h4 v-if="discount > 0 " class="wsSUCCESS text-center" >
      {{ $t('Discount') }}: {{ discount }} %
    </h4>

    <h3 class="wsDARKER text-center mt-1">
      {{ $t('Total') }}: {{ totalPriceWithDiscount }} {{ BUSINESS_CURRENCY }}
    </h3>

  </div>



</div>
</template>

<script>

export default {
  name: "courseCartPrice",
  components : {},
  props : {
    promoDiscount : {
      type : Number,
      default : 0
    },
    entity : {
      type : Object,
      default() {
        return {}
      }
    },
    enrollmentData : {
      type : Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      price : 0
    }
  },

  computed : {
    // Discount
    discount() {

      let discount = this.generalDiscount

      if ( this.promoDiscount > discount ) {
        discount = this.promoDiscount
      }

      return discount

    },
    generalDiscount() {
      let discount = 0

      if (this.entity.discount) {
        discount = this.entity.discount
      }

      if ( this.enrollmentData.quantity ) {
        let periodDiscount = this.getPeriodDiscount(this.enrollmentData.quantity)
        if ( periodDiscount && periodDiscount > discount ) {
          discount = periodDiscount
        }
      }

      return discount
    },
    // Price Calculators
    totalPrice() {

      let price = this.entity.price

      if ( this.entity.has_payment_plans) {
        if ( this.enrollmentData.payment_plan_id ) {
          let entity = this.entity.payment_plans.find(el => el.value === this.enrollmentData.payment_plan_id)
          price = entity.price
        }
      }

      if ( this.enrollmentData.quantity ) {
        price = price * this.enrollmentData.quantity
      }

      return price

    },
    totalPriceWithDiscount() {

      let price = this.totalPrice

      if ( this.discount ) {
        price = price - Math.round(this.discount/100*price)
      }

      return price

    },
    // Warning Booleans
    noPlanSelectedWarning() {
      return this.entity.has_payment_plans && !this.enrollmentData.payment_plan_id
    }
  },
  methods : {
    getPeriodDiscount(period) {
      if (!this.entity.periods_discount || this.entity.periods_discount.length === 0) {
        return null
      }
      let periodDiscount = this.entity.periods_discount.find(el => el.period === period )
      return periodDiscount ? periodDiscount.percent :  null
    },
  }
}
</script>



<style scoped>

</style>