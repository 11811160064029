<template>
  <div  class="mt-5">
    <h5 class="font-weight-regular mb-1"
        :style="`color : ${inputErrors.enrollmentWave ? wsWARNING : wsACCENT}`"
    >
      {{  $t('ChooseEnrollmentSchedule')  }}
    </h5>
    <div :style="`border: 1px solid ${inputErrors.enrollmentWave ? wsWARNING : wsBACKGROUND}; background-color: #ffffff`">
      <div
          @click="entityData.enrollment_wave_id = wave.uuid; inputErrors.enrollmentWave = null "
          v-for="(wave,index) in entity.enrollment_waves" :key="index + 'wave'"
          :style="index > 0 ? 'border-top: 1px solid var(--wsBACKGROUND)' : null"
          class="pa-2 d-flex align-items-center pointer"
      >
        <v-icon class="mr-2" :color="wsACCENT">{{ entityData.enrollment_wave_id === wave.uuid ?  'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}</v-icon>
        <div>
          <h5 class="wsACCENT">{{ wave.name }}</h5>
          <h4 class="wsDARKER" >{{ PARSE_DATE(wave.education_start)  }} - {{ PARSE_DATE(wave.education_end) }}</h4>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "courseCartEnrollmentWaves",
  components : {
  },
  props : {
    value : {
      type : Object,
      default() {
        return {}
      }
    },
    entity : {
      type : Object,
      default() {
        return {}
      }
    },
    inputErrors : {
      type : Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      entityData : {}
    }
  },
  watch : {
    value : {
      handler() {
        if (!this.EQ(this.value , this.entityData)) {
          this.entityData = this.COPY(this.value)
        }
      },
      deep : true
    },
    entityData : {
      handler() {
        if (!this.EQ(this.value , this.entityData)) {
         this.$emit('input' , this.COPY(this.entityData))
        }
      },
      deep : true
    }
  },
  mounted() {
    if (this.value) {
      this.entityData = this.COPY(this.value)
    }
  }
}
</script>



<style scoped>

</style>